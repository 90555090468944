<template>
  <div class="product-details">
  <div class="left-section">
  <div class="image-container">
    <div class="product-image-imp">
      <img :src="currentImage" alt="Изображение товара" />
    </div>
<div class="image-gallery">
  <div
    v-for="(image, index) in filteredImages"
    :key="index"
    class="thumbnail"
    @click="changeImage(image)"
    :class="{ active: image === currentImage }"
  >
    <img :src="image" />
  </div>
</div>

        </div>
        </div>
    <div class="right-section">
    <div class="product-info-inp">
      <p><strong class="product-title">{{ product.title }}</strong></p>
      <p><strong class="product-category">Категория:</strong><br><br><span class="product-category-bg">{{ product.category }}</span></p>
      <p><strong class="product-tags">Теги:</strong><br><br>
        <span v-for="(tag, index) in product.tags" :key="index" class="product-tag-bg">{{ tag }}</span>
      </p>
      <p><strong>Описание:</strong><br><br><span class="product-description-container"><span class="product-description">{{ product.description }}</span></span></p>
      <p><strong>Цена:</strong><br><br><span class="product-price-bg">{{ product.price }} €</span></p>
      <div class="product-actions">
        <div class="counter-and-button">
          <button @click="decrementCounter" class="counter-button">-</button>
          <input type="number" v-model="counter" />
          <button @click="incrementCounter" class="counter-button">+</button>
        </div>
        <button @click="addToCartClicked(counter)" class="add-button">Добавить в корзину</button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import productsData from '@/components/products.json';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      product: {},
      currentImage: '',
      counter: 0,
    };
  },
  computed: {
    productImages() {
      return [
        this.product.image,
        this.product.image1,
        this.product.image2,
        this.product.image3,
      ];
    },
      filteredImages() {
    return this.productImages.filter(image => image);
  },
  },
  created() {
    this.product = productsData.find((product) => product.id === 1);
    this.currentImage = this.product.image;
  },
  methods: {
    ...mapMutations(['addToCart']),
    incrementCounter() {
      this.counter++;
    },
    decrementCounter() {
      if (this.counter > 0) {
        this.counter--;
      }
    },
    addToCartClicked(quantity) {
      this.addToCart({ ...this.product, quantity });
    },
    changeImage(image) {
      this.currentImage = image;
    },
  },
};
</script>

<style>

.product-title {
  font-size: 3vw; /* Измените размер шрифта по вашему желанию */
}


.image-gallery {
  display: flex;
  gap: 10px; /* Расстояние между миниатюрами */
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.thumbnail {
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;
  margin-right: 10px; /* Расстояние между миниатюрами */
}



.thumbnail img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  vertical-align: middle;
  border-radius: 5px; /* Добавьте это свойство для скругления углов */
}

.thumbnail.active {
  border-color: #007BFF; /* Цвет рамки для активной миниатюры */
}

.product-details {
  padding-top: 1%;
  padding-bottom: 5%;
  display: flex;
}

.left-section {
  display: flex;
  flex: 1;
  justify-content: flex-end; /* Выравниваем контент справа */
  margin-right: 2%; /* Добавляем внутренний отступ справа */
}

.right-section {
  flex: 1; /* Правая часть также будет занимать 50% ширины контейнера */
}


.product-price-bg{
    background: red;
    padding: 5px;
    color:white;
    border-radius: 4px;
}

.product-description {
  background-color: #f0f0f0; /* Цвет заднего фона по вашему выбору */
  padding: 10px; /* Поля вокруг текста */
  border-radius: 4px; /* Скругление углов */
  display: inline-block; /* Этот стиль делает элемент блочным внутри текста */
}

.product-description-container {
  max-width: 30vw; /* Замените значение на желаемую максимальную ширину */
  display: inline-block;
}

.product-category-bg {
  background-color: #ffcc00; /* Замените цвет фона на желаемый */
  padding: 5px;
  color: white;
  border-radius: 4px;
}

.product-tag-bg {
  background-color: #33cc33; /* Замените цвет фона на желаемый */
  padding: 5px;
  margin-right: 5px; /* Добавьте небольшой отступ между тегами */
  color: white;
  border-radius: 4px;
}

.product-image-imp {
  margin-bottom: 10px; /* Расстояние между основным изображением и галереей */
  max-width: 20vw; /* Задайте фиксированную ширину контейнера */
  height: 30vw; /* Задайте фиксированную высоту контейнера (выберите подходящее значение) */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden; /* Обрезать изображение, если оно не помещается в контейнер */
}

.product-image-imp img {
  max-width: 100%;
  max-height: 100%; /* Это важно, чтобы изображение вписывалось в контейнер */
  object-fit: contain; /* Используйте 'contain' или 'cover', в зависимости от ваших предпочтений */
}


.product-info-inp {
  flex: 2;
  text-align: left;
   align-self: flex-start;
}

.product-actions {
  display: flex;
  align-items: center;
}

.counter-and-button button {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 18px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.counter-and-button input {
  width: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-button {
  margin-left: 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 4px;
}

.add-button:hover {
  background-color: #0056b3;
}
</style>
