<template>
  <footer>
    <div class="footer-content">
      <div class="footer-logo">
      </div>
      <div class="footer-links">
        <ul>
          <li><a href="#">Главная</a></li>
          <li><a href="#">О нас</a></li>
          <li><a href="#">Контакты</a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

<style scoped>
footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-logo img {
  width: 100px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links li {
  display: inline-block;
  margin-right: 20px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
}
</style>
