// src/utils/cookieUtils.js

export function setCookie(name, value, days) {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    const valueString = typeof value === 'object' ? JSON.stringify(value) : value;
    document.cookie = name + "=" + valueString + ";" + expires + ";path=/";
  }
  
  export function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        const value = c.substring(nameEQ.length, c.length);
        try {
          return JSON.parse(value);
        } catch (e) {
          return value;
        }
      }
    }
    return null;
  }
  
  export function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
  }
  