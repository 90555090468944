<template>
  <header class="sticky-header">
    <!-- Левый блок с логотипом -->
    <div class="left-block">
      <div class="logo">
        <img src="@/assets/white@4x.png" alt="Лого" />
      </div>
    </div>

    <!-- Центральный блок с меню -->
    <div class="center-block">
      <nav class="nav-links">
        <router-link class="linkPage" to="/">{{ $t('home') }}</router-link>
        <router-link class="linkPage" to="/products">{{ $t('products') }}</router-link>
        <router-link class="linkPage" to="/about">{{ $t('about') }}</router-link>
      </nav>
    </div>

    <!-- Правый блок с корзиной и переключателем языков -->
    <div class="right-block">
      <!-- Блок корзины -->
      <div class="cart-container">
        <div class="cart" @click="toggleCart">
          <div class="cart-icon">
            <img src="/images/263142.png" alt="Корзина" />
          </div>
          <div class="cart-text">
            <p>{{ finalPrice }} €</p> <!-- Итоговая сумма заказа -->
          </div>
          <div class="cart-count">{{ totalQuantity }}</div> <!-- Количество товаров -->
        </div>
        <div class="cart-list" v-if="isCartOpen">
          <div v-for="product in cartWithDetails" :key="product.id">
            <img :src="product.images.length ? product.images[0] : '/path/to/default/image.png'" alt="Товар" />
            <p>{{ $t('price') }}: {{ product.price }} €</p>
            <p>{{ $t('quantity') }}: {{ product.quantity }}</p>
          </div>
          <button @click="goToCartPageAndCloseCart">{{ $t('goToCart') }}</button>
        </div>
        <div v-if="isCartOpen" class="transparent-layer" @click="closeCart"></div>
      </div>

      <!-- Переключатель языков -->
      <div class="language-switcher">
        <div @click="toggleDropdown" class="language-button">
          {{ currentLanguageLabel }}
          <span class="arrow"></span>
        </div>
        <div v-if="dropdownOpen" class="language-dropdown">
          <div v-for="language in filteredLanguages" :key="language.code" @click="switchLanguage(language.code)" class="language-option">
            {{ language.label }}
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import productsData from "@/components/products.json";

export default {
  data() {
    return {
      isCartOpen: false,
      dropdownOpen: false,
      languages: [
        { code: 'est', label: 'EST' },
        { code: 'rus', label: 'RUS' }
      ]
    };
  },
  created() {
    const selectedLanguage = this.getCookie('selectedLanguage');
    if (selectedLanguage) {
      this.switchLanguage(selectedLanguage);
    }
  },
  methods: {
    goToCartPage() {
      this.$router.push({ name: 'cart' });
    },
    toggleCart() {
      this.isCartOpen = !this.isCartOpen;
    },
    closeCart() {
      this.isCartOpen = false;
    },
    goToCartPageAndCloseCart() {
      this.goToCartPage();
      this.closeCart();
    },
    switchLanguage(languageCode) {
      this.$store.commit('setLanguage', languageCode);
      this.setCookie('selectedLanguage', languageCode, 365); // Сохраняем выбранный язык в cookie
      this.dropdownOpen = false;
      console.log(`Выбран язык: ${languageCode}`);
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      let nameEQ = name + "=";
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
  },
  computed: {
    ...mapGetters(['finalPrice', 'totalQuantity']), // Используем геттеры Vuex
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },
    currentLanguageLabel() {
      const language = this.languages.find(lang => lang.code === this.currentLanguage);
      return language ? language.label : '';
    },
    filteredLanguages() {
      return this.languages.filter(language => language.code !== this.currentLanguage);
    },
    cart() {
      return this.$store.state.cart;
    },
    cartWithDetails() {
      return this.cart.map(cartItem => {
        const product = productsData.find(p => p.id === cartItem.id);
        if (product) {
          return {
            ...cartItem,
            images: product.images,
            price: product.price, // Обновление цены из products.json
          };
        }
        return cartItem;
      });
    },
  },
};
</script>

<style scoped>
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Установка высоты дочерних элементов по максимальной высоте */
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.4); /* Add box shadow */
}

.transparent-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 999; /* Выше всех других элементов */
}

.left-block {
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center; /* Горизонтальное выравнивание по центру */
  background-color: #f74fa9;
}

.logo img {
  max-width: 40%; /* Лого будет растягиваться по ширине блока */
  height: auto;
  display: flex;
  align-items: center;
}

.center-block {
  height: 8vh;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center; /* Горизонтальное выравнивание по центру */
  background-color: #f74fa9;
}

.right-block {
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Горизонтальное выравнивание по центру */
  background-color: #f74fa9;
}

.left-block, .center-block, .right-block {
  flex-grow: 1;
  flex-shrink: 0;
  width: 33.33%;
}

.menu-part {
  width: 85%; /* Например, 50% для второй части (меню) */
}

.cart {
  background-color: white; /* Серый фон */
  margin-right: 30px;
  max-height: 3vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 10px;
  border-radius: 5px;
  position: relative; /* Добавьте это свойство, чтобы позиционировать дочерний элемент относительно этого блока */
  border: 2px solid #f74fa9;
  border-top: none; /* Убираем верхний border */
}

.cart-count {
  background-color: #e91e63;
  color: white;
  font-size: 14px;
  padding: 2px 5px;
  position: absolute;
  border-radius: 99px;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.cart-icon img {
  max-width: 1.5vw; /* Установите желаемую максимальную ширину */
  height: auto;
  margin-right: 10px; /* Расстояние между иконкой и текстом */
}

.nav-links {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.linkPage {
  text-decoration: none;
  color: white;
  font-size: 16px;
  margin-right: 20px;
}

.cart i {
  font-size: 24px;
  margin-right: 5px;
}

.cart-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: black;
}

.cart-container {
  position: relative;
}

.cart-list {
  position: absolute; /* Позиционирование элемента внутри контекста .cart */
  top: 100%; /* Положение списка под кнопкой корзины */
  right: 0;
  width: 300px;
  background-color: white;
  color: black;
  border: 2px solid #f74fa9;
  border-radius: 5px;
  padding: 10px;
  z-index: 1001;
}

.cart-list div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.cart-list img {
  max-width: 50px; /* Установите желаемую максимальную ширину для изображения товара */
  height: auto;
  margin-right: 10px;
}

.cart-list p {
  margin: 0;
}

.cart-list button {
  width: 100%;
  background-color: #f74fa9;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cart-list button:hover {
  background-color: #e52d87;
}

.language-switcher {
  position: relative;
  display: inline-block;
}

.language-button {
  background-color: white;
  border: none;
  color: black;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.language-button .arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
  transform: rotate(45deg);
}

.language-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #FFE0FC;
  color: black;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
}

.language-option {
  padding: 12px 16px;
  cursor: pointer;
}

.language-option:hover {
  background-color: white;
}
</style>
