<template>
  <div class="products-page">
    <div class="filters">
      <h1>{{ $t('filters.filterTitle') }}</h1>
      <div class="filter">
        <label class="filter-label">{{ $t('filters.searchByNameLabel') }}</label>
        <div class="filter-content">
          <input type="text" id="searchQuery" v-model="searchQuery">
        </div>
      </div>

      <div class="filter">
        <label class="filter-label">{{ $t('filters.priceRangeLabel') }}</label>
        <div class="filter-content">
          <ul>
            <li>
              <input type="radio" v-model="selectedPriceRange" value="0-20" id="price-range-1" name="price-range">
              <label for="price-range-1">{{ $t('filters.priceRanges.0-20') }}</label>
            </li>
            <li>
              <input type="radio" v-model="selectedPriceRange" value="20-50" id="price-range-2" name="price-range">
              <label for="price-range-2">{{ $t('filters.priceRanges.20-50') }}</label>
            </li>
            <li>
              <input type="radio" v-model="selectedPriceRange" value="50-100" id="price-range-3" name="price-range">
              <label for="price-range-3">{{ $t('filters.priceRanges.50-100') }}</label>
            </li>
            <li>
              <input type="radio" v-model="selectedPriceRange" value="100-200" id="price-range-4" name="price-range">
              <label for="price-range-4">{{ $t('filters.priceRanges.100-200') }}</label>
            </li>
            <li>
              <input type="radio" v-model="selectedPriceRange" value="200" id="price-range-5" name="price-range">
              <label for="price-range-5">{{ $t('filters.priceRanges.200+') }}</label>
            </li>
          </ul>
        </div>
      </div>

<div class="filter">
  <label class="filter-label">{{ $t('filters.categoryLabel') }}</label>
  <div class="filter-content category-list">
    <div class="category-item" v-for="category in translatedCategories" :key="category.key">
      <input type="checkbox" :id="`category-${category.key}`" :value="category.key" v-model="selectedCategories">
      <label :for="`category-${category.key}`" class="category-label">{{ category.name }}</label>
    </div>
  </div>
</div>

<div class="filter">
  <label class="filter-label">{{ $t('filters.tagsLabel') }}</label>
  <div class="filter-content tag-list">
    <div class="tag-item" v-for="tag in translatedTags" :key="tag.key">
      <input type="checkbox" :id="`tag-${tag.key}`" :value="tag.key" v-model="selectedTags">
      <label :for="`tag-${tag.key}`" class="tag-label">{{ tag.name }}</label>
    </div>
  </div>
</div>

      <button class="buttonFilter" type="submit" @click="filterProducts">{{ $t('filters.applyButton') }}</button>
      <button class="clearFilter" @click="clearFilter">{{ $t('filters.clearButton') }}</button>
    </div>

    <div class="product-list">
      <div v-for="product in localizedProducts" :key="product.id" class="product-card">
      <div class="product-square" :style="{ backgroundImage: 'url(' + product.images[0] + ')' }"></div>
  <h3>{{ product.title }}</h3>
  <p>{{ $t('category') }}: {{ product.category[Object.keys(product.category)[0]] }}</p>
  <p>{{ $t('filters.tagsLabel') }}: {{ Object.values(product.tags).join(', ') }}</p>
  <p>{{ $t('price') }}: ${{ product.price }}</p>
  <div class="product-actions">
    <button class="btn-details" @click="viewDetails(product.id)">{{ $t('product.detailsButton') }}</button>
    <button class="btn-add-to-cart" @click="addToCart(product)">{{ $t('product.addToCartButton') }}</button>
  </div>
</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import productsData from '@/components/products.json';
import translationsEE from '@/locales/est/ProductsTranslationsEE.json';
import translationsRU from '@/locales/rus/ProductsTranslationsRU.json';
import router from '@/router';

export default {
  data() {
    return {
      originalProducts: productsData,
      products: [],
      searchQuery: '',
      selectedPriceRange: '',
      selectedCategories: [],
      selectedTags: [],
    };
  },

  computed: {
    ...mapGetters({
      currentLanguage: 'currentLanguage',
      getProductTranslation: 'getProductTranslation',
      getCategoryTranslations: 'getCategoryTranslations',
      getTagTranslation: 'getTagTranslation'
    }),

    localizedProducts() {
      return this.products.map(product => {
        const translation = this.getProductTranslation(product.id) || {};
        return {
          ...product,
          ...translation,
        };
      });
    },

translatedCategories() {
  // Используйте originalProducts для получения всех категорий
  const categoryKeys = [...new Set(this.originalProducts.flatMap(product => Object.keys(product.category)))];
  return categoryKeys.map(key => ({
    key,
    name: this.getCategoryTranslations(key) // Получаем локализованное название
  }));
},

translatedTags() {
  // Используйте originalProducts для получения всех тегов
  const tagKeys = [...new Set(this.originalProducts.flatMap(product => Object.keys(product.tags)))];
  return tagKeys.map(key => ({
    key,
    name: this.getTagTranslation(key)
  }));
},


    categories() {
    const categories = new Set();
    this.originalProducts.forEach(product => {
      Object.values(product.category).forEach(category => {
        categories.add(category);
      });
    });
    return Array.from(categories);
  },

  
    
  },

  watch: {
    currentLanguage(newLang, oldLang) {
      if (newLang !== oldLang) {
        this.products = this.localizedProducts;
      }
    },
  },

  created() {
    this.loadTranslations();
  },

  methods: {
    ...mapMutations(['addToCart', 'setToPay']),

    loadTranslations() {
      const language = this.currentLanguage;
      console.log(`Текущий язык: ${language}`);

      const translations = language === 'ee' ? translationsEE : translationsRU;
      console.log(`Загруженные переводы:`, translations);

      this.originalProducts.forEach(product => {
        const productTranslations = translations[product.id.toString()];
        if (productTranslations) {
          product.title = productTranslations.title;
          product.description = productTranslations.description;
          product.category = productTranslations.category;
          product.tags = productTranslations.tags;
        }
      });

      console.log(`Обновленные данные продуктов:`, this.originalProducts);
      this.filterProducts();
    },

filterProducts() {
  this.products = this.originalProducts.filter(product => {
    const matchesTitle = this.matchTitle(product);
    const matchesPrice = this.matchPrice(product);
    const matchesCategory = this.selectedCategories.length === 0 || this.selectedCategories.some(categoryKey => Object.keys(product.category).includes(categoryKey));
    const matchesTag = this.selectedTags.length === 0 || this.selectedTags.some(tagKey => Object.keys(product.tags).includes(tagKey));
    return matchesTitle && matchesPrice && matchesCategory && matchesTag;
  });
},



    matchTitle(product) {
      return this.searchQuery.length === 0 || product.title.toLowerCase().includes(this.searchQuery.toLowerCase());
    },

    matchPrice(product) {
      if (!this.selectedPriceRange) return true;
      const [minPrice, maxPrice] = this.selectedPriceRange.split('-').map(Number);
      if (maxPrice) {
        return product.price >= minPrice && product.price <= maxPrice;
      }
      return product.price >= minPrice;
    },

    viewDetails(product) {
      const productPageURL = `/${product.urlPage}`;
      router.push(productPageURL);
    },

    addToCart(product) {
      this.$store.commit('addToCart', { productId: product.id, quantity: 1 });
      this.$store.dispatch('saveCartAndDeliveryToServer');
    },

clearFilter() {
  this.searchQuery = '';
  this.selectedPriceRange = '';
  this.selectedCategories = [];
  this.selectedTags = []; // Сброс выбранных тегов
  this.filterProducts();
},

  },
};
</script>

<style scoped>
.product-square {
  width: 100%;
  padding-top: 100%; /* Создает квадрат */
  background-color: #f0f0f0;
  margin-bottom: 8px;
  background-size: cover; /* Изображение полностью покрывает элемент */
  background-position: center; /* Центрирует изображение */
  background-repeat: no-repeat; /* Предотвращает повторение изображения */
}


.product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.products-page {
  display: flex;
  justify-content: flex-start; /* Выравнивание контейнера к левому краю */
}
.filters {
  width: 20%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: #ffd9e6;
}
.filter {
  margin-bottom: 16px; /* Отступ между фильтрами */
}
.filter-content input[type="text"] {
  width: 100%; /* Растягиваем поле ввода на 100% ширины родительского элемента */
  /* Другие стили для поля ввода, если необходимо */
}
.category-checkbox {
  display: flex;
  align-items: center; /* Выравнивание чекбокса и лейбла по центру */
  padding-bottom: 8px;
}
.category-list {
  display: flex;
  flex-direction: column;
  padding-top: 6px;
}
.category-item {
  padding-bottom: 6px; /* Отступ между элементами списка */
}
.tag-checkbox {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

.filter-label {
  display: block; /* Сделаем заголовок блочным элементом, чтобы он занимал всю ширину родительского контейнера */
  border-bottom: 1px solid black; /* Горизонтальная полоса под названием фильтра */
  padding: 8px 0; /* Отступы только сверху и снизу */
  margin-bottom: 8px; /* Отступ между фильтрами */
  text-align: left; /* Выравнивание текста слева */
  color: black;
  font-size: 19px;
  font-weight: 400;
}
.filter-content {
  margin-top: 8px; /* Оставляем прежний отступ */
}
.filter-content input[type="text"] {
  width: calc(100% - 2%); /* Изменяем ширину поля ввода, учитывая внутренние отступы */
}
.buttonFilter{
  margin-bottom: 10px;
}
.product-list {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start; /* Убедитесь, что используете align-items: flex-start, чтобы элементы выравнивались от верхней границы контейнера */
}

.product-card {
  border: 1px solid #ccc;
  margin: 16px;
  padding: 0; /* Remove internal padding */
  width: calc(33.33% - 32px);
  box-sizing: border-box;
  background-color: #fff;
display: flex; /* Добавьте этот стиль, чтобы использовать flexbox */
  flex-direction: column; /* Установите вертикальное направление */
  justify-content: flex-start;  
  align-items: center; /* Выравнивание содержимого по центру по горизонтали */
  text-align: center;
}
.product-image-products-page {
  max-width: 100%;
  height: auto;
}
.product-price {
  font-weight: bold;
  margin-top: 8px;
}
.product-category {
  font-style: italic;
  margin-top: 8px;
  text-align: center;
  flex-grow: 1;
}
.product-tags-info {
  margin-top: 8px;
  text-align: center; /* Center-align tags */
}
.product-tags-info span {
  background-color: #f0f0f0;
  padding: 2px 6px;
  margin-right: 4px;
  border-radius: 4px;
}
.category-list {
  display: flex;
  flex-wrap: wrap;
}
.category-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.category-item input[type="checkbox"] {
  margin-right: 8px;
}
.selected {
  font-weight: bold; /* Подчеркиваем выбранные категории */
}
.filter-content ul {
  list-style: none; /* Убираем маркеры списка */
  padding: 0; /* Убираем отступы слева */
}
.filter-content li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.filter-content label {
  margin-left: 8px; /* Добавляем небольшой отступ между флажком и текстом */
}
.product-actions {
  display: flex;
  justify-content: space-between; /* Располагает кнопки с промежутком между ними */
  width: 100%; /* Занимает 100% ширины карточки товара */
  padding: 10px; /* Предполагается, что внутренние отступы уже есть для всей карточки */
  margin-top: auto; /* Прижимает кнопки к нижней части карточки */
  box-sizing: border-box; /* Учитывает padding и border в общей ширине элемента */
}
.btn-details, .btn-add-to-cart {
  width: calc(50% - 5px); /* Вычисляет ширину как половину карточки минус половина отступа между кнопками */
  text-align: center; /* Выравнивание текста по центру */
  box-sizing: border-box; /* Включает в расчет ширины padding и border */
  padding: 10px 0; /* Добавляет вертикальные отступы */
  color: white; /* Цвет текста */
  border: 0px;
}
.btn-add-to-cart {
  background-color: #e74c3c; /* Цвет фона для кнопки добавления в корзину */
}
.btn-details {
  background-color: #3498db; /* Цвет фона для кнопки деталей */
}
.buttonFilter, .clearFilter {
  width: 100%; /* Ширина кнопки равна ширине ее родительского контейнера */
  padding: 10px; /* Внутренние отступы кнопки */
  background-color: #f74fa9; /* Цвет фона кнопки */
  color: #fff; /* Цвет текста кнопки */
  border: none; /* Убираем границу кнопки */
  cursor: pointer; /* Задаем стандартный курсор при наведении на кнопку */
  margin-top: 10px; /* Внешний отступ сверху кнопки */
  border-radius: 4px; /* Скругляем углы кнопки */
}
.buttonFilter:hover, .clearFilter:hover {
  background-color: #c9408a; /* Изменяем цвет фона кнопки при наведении */
}
.tag-list {
  display: flex;
  flex-direction: column;
  padding-top: 6px;
}

.tag-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.tag-item input[type="checkbox"] {
  margin-right: 8px;
}

.tag-label {
  display: block;
  margin-left: 8px;
  cursor: pointer;
}

</style>
