import { createStore } from 'vuex';
import axios from 'axios';
import ProductsTranslationsEE from './locales/est/ProductsTranslationsEE.json';
import ProductsTranslationsRU from './locales/rus/ProductsTranslationsRU.json';
import CategoriesTranslationsEE from './locales/est/CategoriesTranslationsEE.json';
import CategoriesTranslationsRU from './locales/rus/CategoriesTranslationsRU.json';
import TagTranslationsEE from './locales/est/TagTranslationsEE.json';
import TagTranslationsRU from './locales/rus/TagTranslationsRU.json';
import productsData from '@/components/products.json';
import { setCookie, getCookie } from '@/utils/cookieUtils';

function generateUserId(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let userId = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    userId += characters.charAt(randomIndex);
  }
  return userId;
}

function setUserIdCookie() {
  const existingUserId = getCookie('userId');

  if (!existingUserId) {
    const userId = generateUserId(10); // Генерация userId
    const expirationDate = new Date('9999-12-31T23:59:59Z').toUTCString(); // Очень долгий срок действия
    document.cookie = `userId=${userId}; expires=${expirationDate}; path=/`;
  }
}

function updateCartCookie(cart, deliveryType = null, deliveryCost = 0) {
  const totalQuantity = cart.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = cart.reduce((total, item) => total + item.totalPrice, 0);
  const finalPrice = totalPrice + deliveryCost; // Итоговая стоимость с учетом доставки
  setCookie('cart', { items: cart, totalQuantity, totalPrice, deliveryType, deliveryCost, finalPrice }, 7); // Save cart to cookie for 7 days
}

setUserIdCookie();

export default createStore({
  state: {
    productsData: productsData,
    currentLanguage: 'est',
    productTranslations: {
      est: ProductsTranslationsEE,
      rus: ProductsTranslationsRU,
    },
    categoryTranslations: {
      est: CategoriesTranslationsEE,
      rus: CategoriesTranslationsRU,
    },
    tagTranslations: {
      est: TagTranslationsEE,
      rus: TagTranslationsRU,
    },
    token: null,
    cart: getCookie('cart')?.items || [], // Загрузка корзины из cookie при инициализации
    totalQuantity: getCookie('cart')?.totalQuantity || 0, // Загрузка общего количества из cookie
    totalPrice: getCookie('cart')?.totalPrice || 0, // Загрузка общей суммы из cookie
    deliveryType: getCookie('cart')?.deliveryType || null, // Загрузка типа доставки из cookie
    deliveryCost: getCookie('cart')?.deliveryCost || 0, // Загрузка стоимости доставки из cookie
    finalPrice: getCookie('cart')?.finalPrice || 0, // Загрузка итоговой стоимости из cookie
    toPay: null, // Общая сумма к оплате
    upCost: 0,
    user: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      deliveryAddress: "",
      orderNote: "",
    },
    userId: getCookie('userId') || null, // Загрузка userId из cookie
    paymentLink: null, // Добавляем новое состояние
  },
  mutations: {
    addToCart(state, { productId, quantity }) {
      const product = state.productsData.find(p => p.id === productId);
      const existingProductIndex = state.cart.findIndex(item => item.id === productId);

      if (existingProductIndex !== -1) {
        state.cart[existingProductIndex].quantity += quantity;
        state.cart[existingProductIndex].totalPrice = state.cart[existingProductIndex].quantity * state.cart[existingProductIndex].price;
      } else {
        state.cart.push({
          id: productId,
          name: product.name,
          price: product.price,
          quantity: quantity,
          totalPrice: product.price * quantity,
          image: product.image
        });
      }
      updateCartCookie(state.cart, state.deliveryType, state.deliveryCost); // Save cart to cookie
      state.totalQuantity = state.cart.reduce((total, item) => total + item.quantity, 0);
      state.totalPrice = state.cart.reduce((total, item) => total + item.totalPrice, 0);
      state.finalPrice = state.totalPrice + state.deliveryCost; // Обновляем итоговую стоимость
    },
    incrementQuantity(state, product) {
      const cartProduct = state.cart.find(item => item.id === product.id);
      if (cartProduct) {
        cartProduct.quantity++;
        cartProduct.totalPrice = cartProduct.quantity * cartProduct.price;
      }
      updateCartCookie(state.cart, state.deliveryType, state.deliveryCost); // Save cart to cookie
      state.totalQuantity = state.cart.reduce((total, item) => total + item.quantity, 0);
      state.totalPrice = state.cart.reduce((total, item) => total + item.totalPrice, 0);
      state.finalPrice = state.totalPrice + state.deliveryCost; // Обновляем итоговую стоимость
    },
    decrementQuantity(state, product) {
      const cartProduct = state.cart.find(item => item.id === product.id);
      if (cartProduct && cartProduct.quantity > 1) {
        cartProduct.quantity--;
        cartProduct.totalPrice = cartProduct.quantity * cartProduct.price;
      }
      updateCartCookie(state.cart, state.deliveryType, state.deliveryCost); // Save cart to cookie
      state.totalQuantity = state.cart.reduce((total, item) => total + item.quantity, 0);
      state.totalPrice = state.cart.reduce((total, item) => total + item.totalPrice, 0);
      state.finalPrice = state.totalPrice + state.deliveryCost; // Обновляем итоговую стоимость
    },
    setDeliveryType(state, type) {
      state.deliveryType = type;
      updateCartCookie(state.cart, state.deliveryType, state.deliveryCost); // Save cart to cookie
      state.finalPrice = state.totalPrice + state.deliveryCost; // Обновляем итоговую стоимость
      console.log('Тип доставки установлен:', type);
    },
    setDeliveryCost(state, cost) {
      state.deliveryCost = cost;
      updateCartCookie(state.cart, state.deliveryType, state.deliveryCost); // Save cart to cookie
      state.finalPrice = state.totalPrice + state.deliveryCost; // Обновляем итоговую стоимость
      console.log('Стоимость доставки установлена:', cost);
    },
    setToPay(state, toPay) {
      state.toPay = toPay;
      console.log('Значение toPay обновлено:', toPay);
    },
    setUpCost(state, upCost) {
      state.upCost = upCost;
      console.log("Обновленное значение upCost:", upCost); // Выводим обновленное значение upCost в консоль
    },
    updateTotalCost(state, newTotalCost) {
      state.totalCost = newTotalCost;
      console.log("Обновление totalCost:", newTotalCost);
    },
    updateTotalQuantity(state) {
      state.totalQuantity = state.cart.reduce((total, product) => total + product.quantity, 0);
    },
    removeFromCart(state, productId) {
      const productIndex = state.cart.findIndex(item => item.id === productId);
      if (productIndex !== -1) {
        state.cart.splice(productIndex, 1);
      }
      updateCartCookie(state.cart, state.deliveryType, state.deliveryCost); // Save cart to cookie
      state.totalQuantity = state.cart.reduce((total, item) => total + item.quantity, 0);
      state.totalPrice = state.cart.reduce((total, item) => total + item.totalPrice, 0);
      state.finalPrice = state.totalPrice + state.deliveryCost; // Обновляем итоговую стоимость
    },
    loadCartFromCookie(state) {
      const cartData = getCookie('cart');
      if (cartData) {
        state.cart = cartData.items;
        state.totalQuantity = cartData.totalQuantity;
        state.totalPrice = cartData.totalPrice;
        state.deliveryType = cartData.deliveryType;
        state.deliveryCost = cartData.deliveryCost;
        state.finalPrice = cartData.finalPrice;
      }
    },
    setUserData(state, userData) {
      state.user = { ...state.user, ...userData };
      console.log('Данные пользователя обновлены:', state.user);
    },
    setToken(state, token) {
      state.token = token;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setContactInfo(state, contactInfo) {
      state.user = { ...state.user, ...contactInfo };
    },
    setCart(state, cart) {
      state.cart = cart.items;
      state.totalQuantity = cart.totalQuantity;
      state.totalPrice = cart.totalPrice;
      state.deliveryType = cart.deliveryType;
      state.deliveryCost = cart.deliveryCost;
      state.finalPrice = cart.finalPrice;
    },
    logInfo(state) {
      console.log('UserId:', state.userId);
      console.log('Contact Info:', state.user);
      console.log('Cart:', state.cart);
    },
    addUserIdToCart(state, userId) {
      state.userIdCart.push(userId);
    },
    setPaymentLink(state, link) {
      state.paymentLink = link;
      console.log('Получена ссылка на оплату:', link);
    },
    setLanguage(state, languageCode) {
      state.currentLanguage = languageCode;
      console.log('Выбран язык vuex:', languageCode); // Выводим информацию о выбранном языке в консоль
    },
  },
  actions: {
    loadCart({ commit }) {
      commit('loadCartFromCookie');
    },
    removeFromCart({ commit }, product) {
      commit('removeFromCart', product); // Вызываем мутацию для удаления товара
    },
    checkUserIdFromCookie({ commit }) {
      const existingUserId = getCookie('userId');
      if (existingUserId) {
        commit('addUserIdToCart', existingUserId);
      }
    },
    async addUserIdToCart({ commit }, userId) {
      commit('addUserIdToCart', userId); // Мутация для добавления userId в userIdCart
    },
    checkLanguageFromCookie({ commit }) {
      const languageCode = getCookie('selectedLanguage');
      if (languageCode) {
        commit('setLanguage', languageCode);
        console.log('Язык из cookie:', languageCode); // Вывод в консоль
      }
    },
    changeLanguage({ commit }, languageCode) {
      commit('setLanguage', languageCode);
    },
    fetchDataFromCookies({ commit }) {
      const userId = getCookie('userId');
      const contactInfo = getCookie('contactInfo');
      const cart = getCookie('cart');

      if (userId) {
        commit('setUserId', userId);
      }
      if (contactInfo) {
        commit('setContactInfo', contactInfo);
      }
      if (cart) {
        commit('setCart', cart);
      }
      commit('logInfo');
    },
    async processOrder({ state }) {
      try {
        // Используем server.belfiore.ee для всех API-запросов
        const apiUrl = 'https://server.belfiore.ee';

        const response = await axios.post(`${apiUrl}/process-order`, {
          userId: state.userId,
          contactInfo: state.user,
          cart: {
            items: state.cart,
            totalQuantity: state.totalQuantity,
            totalPrice: state.totalPrice,
            deliveryType: state.deliveryType,
            deliveryCost: state.deliveryCost,
            finalPrice: state.finalPrice,
          }
        });
        const paymentLink = response.data.paymentLink;
        this.commit('setPaymentLink', paymentLink);
        return paymentLink;
      } catch (error) {
        console.error('Ошибка при обработке заказа:', error);
        throw new Error('Ошибка при обработке заказа');
      }
    },
  },
  getters: {
    cart: state => state.cart,
    totalQuantity: state => state.totalQuantity,
    totalPrice: state => state.totalPrice,
    deliveryType: state => state.deliveryType,
    deliveryCost: state => state.deliveryCost,
    finalPrice: state => state.finalPrice,
    toPay: state => state.toPay,
    upCost: state => state.upCost,
    totalCost: state => state.totalCost,
    paymentLink: state => {
      console.log('Getter paymentLink:', state.paymentLink);
      return state.paymentLink;
    },
    currentLanguage(state) {
      return state.currentLanguage;
    },
    getProductTranslation: state => productId => {
      const language = state.currentLanguage; // Получаем текущий язык
      const translations = state.productTranslations[language]; // Получаем переводы для этого языка
      return translations[productId]; // Возвращаем перевод для конкретного товара
    },
    getCategoryTranslations: state => categoryKey => {
      const language = state.currentLanguage; // 'est' или 'rus'
      const translations = language === 'est'
        ? state.categoryTranslations.est.filtersEST
        : state.categoryTranslations.rus.filtersRUS;
      return translations[categoryKey] || categoryKey; // Возвращаем перевод для ключа категории или ключ, если перевод отсутствует
    },
    getTagTranslation: state => tagKey => {
      const language = state.currentLanguage; // 'est' или 'rus'
      const translations = language === 'est'
        ? state.tagTranslations.est.filtersTagEST
        : state.tagTranslations.rus.filtersTagRUS;
      return translations[tagKey] || tagKey; // Возвращаем перевод для ключа категории или ключ, если перевод отсутствует
    },
  },
});
