<template>
  <div class="payment-page">
    <div class="content">
      <div class="left-section with-background">
        <h2>{{ $t('payment.contactInfo') }}</h2>
        <div class="contact-info">
          <div class="input-group">
            <label for="firstName">{{ $t('payment.firstName') }}:</label>
            <input id="firstName" v-model="firstName" @input="setContactInfoCookie" :placeholder="$t('payment.firstNamePlaceholder')" />
          </div>
          <div class="input-group">
            <label for="lastName">{{ $t('payment.lastName') }}:</label>
            <input id="lastName" v-model="lastName" @input="setContactInfoCookie" :placeholder="$t('payment.lastNamePlaceholder')" />
          </div>
          <div class="input-group">
            <label for="phoneNumber">{{ $t('payment.phoneNumber') }}:</label>
            <input id="phoneNumber" v-model="phoneNumber" @input="setContactInfoCookie" :placeholder="$t('payment.phoneNumberPlaceholder')" />
          </div>
          <div class="input-group">
            <label for="email">{{ $t('payment.email') }}:</label>
            <input id="email" v-model="email" @input="setContactInfoCookie" :placeholder="$t('payment.emailPlaceholder')" />
          </div>
          <div class="input-group">
            <label for="city">{{ $t('payment.city') }}:</label>
            <input id="city" v-model="city" @input="setContactInfoCookie" :placeholder="$t('payment.cityPlaceholder')" />
          </div>
          <div class="input-group">
            <label for="deliveryAddress">{{ $t('payment.deliveryAddress') }}:</label>
            <input id="deliveryAddress" v-model="deliveryAddress" @input="setContactInfoCookie" :placeholder="$t('payment.deliveryAddressPlaceholder')" />
          </div>
          <div class="input-group">
            <label for="orderNote">{{ $t('payment.orderNote') }}:</label>
            <textarea id="orderNote" v-model="orderNote" @input="setContactInfoCookie" :placeholder="$t('payment.orderNotePlaceholder')"></textarea>
          </div>
        </div>
      </div>
      <div class="right-section">
        <div class="card with-background">
          <h2>{{ $t('payment.paymentAmount') }}</h2>
          <div class="payment-summary">
            <p style="text-align: left;">{{ finalPrice }} €</p>
          </div>
          <h2>{{ $t('payment.selectedDeliveryType') }}</h2>
          <div class="payment-summary">
            <p style="text-align: left;">
              {{ $t(`payment.deliveryTypes.${$store.state.deliveryType}`) }}
            </p>
          </div>
        </div>
        <div class="card with-background">
          <h2>{{ $t('payment.paymentOption') }}</h2>
          <div class="payment-options">
            <button @click="payWithStore('BankA')" :class="{'button-clicked': selectedPaymentMethod === 'BankA'}" class="payment-button" style="background-image: url('/images/EveryPay_logo.png');" id="payment-button-BankA">
            </button>
          </div>
        </div>
        <div class="bottom-section">
          <button @click="placeOrder" class="order-button" :disabled="!selectedPaymentMethod">{{ $t('payment.placeOrder') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      city: "",
      deliveryAddress: "",
      orderNote: "",
      selectedPaymentMethod: null,
    };
  },

  computed: {
    ...mapGetters(['totalCost', 'finalPrice']), // Пример использования геттера из Vuex
  },

  methods: {
    payWithStore(method) {
      this.selectedPaymentMethod = method;
      console.log("Выбранный способ оплаты:", method);
      // Дополнительная логика...
    },

    async placeOrder() {
      // Проверка заполнения обязательных полей
      if (!this.firstName || !this.lastName || !this.phoneNumber || !this.email || !this.city || !this.deliveryAddress) {
        alert('Пожалуйста, заполните все обязательные поля.');
        return;
      }
      if (!this.selectedPaymentMethod) {
        alert('Пожалуйста, выберите способ оплаты.');
        return;
      }

      // Сохранение данных пользователя в Vuex
      const orderData = {
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        email: this.email,
        city: this.city,
        deliveryAddress: this.deliveryAddress,
        orderNote: this.orderNote,
      };
      this.$store.commit('setUserData', orderData);

      // Отправка запроса в Vuex для получения данных из куки и отправки данных на сервер
      try {
        const paymentLink = await this.$store.dispatch('processOrder');
        console.log('Payment Link:', paymentLink);

        // Перенаправление на страницу оплаты
        window.location.href = paymentLink;
      } catch (error) {
        console.error('Ошибка при обработке заказа:', error);
      }

      // Очистка формы
      this.clearFormData();
    },

    clearFormData() {
      this.firstName = "";
      this.lastName = "";
      this.phoneNumber = "";
      this.email = "";
      this.city = "";
      this.deliveryAddress = "";
      this.orderNote = "";
    },

    setContactInfoCookie() {
      const contactInfo = {
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        email: this.email,
        city: this.city,
        deliveryAddress: this.deliveryAddress,
        orderNote: this.orderNote,
      };
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);
      document.cookie = `contactInfo=${JSON.stringify(contactInfo)};expires=${expirationDate.toUTCString()};path=/`;
    },

    loadContactInfoFromCookie() {
      const contactInfoCookie = this.getCookie('contactInfo');
      if (contactInfoCookie) {
        const contactInfo = JSON.parse(contactInfoCookie);
        this.firstName = contactInfo.firstName || "";
        this.lastName = contactInfo.lastName || "";
        this.phoneNumber = contactInfo.phoneNumber || "";
        this.email = contactInfo.email || "";
        this.city = contactInfo.city || "";
        this.deliveryAddress = contactInfo.deliveryAddress || "";
        this.orderNote = contactInfo.orderNote || "";
      }
    },

    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
  },

  created() {
    this.loadContactInfoFromCookie();
  },
};
</script>

<style scoped>
.payment-page {
  background-color: #ffd9e6;
  padding-top: 1%;
  padding-bottom: 13%;
}
.content {
  display: flex;
  justify-content: space-between;
  margin-left: 10%;
  margin-right: 10%;
  background-color: #ffd9e6;
}
.left-section {
  flex: 1;
  padding: 20px;
  text-align: left;
  display: block;
}
.card {
  padding: 20px;
  margin-bottom: 20px;
  text-align: left; /* Выравнивание по левой стороне */
}
.with-background {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #f74fa9;
}
.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}
.input-group textarea {
  max-height: calc(100vh - 80vh); /* Максимальная высота (высота окна минус высота всех элементов над textarea) */
  max-width: calc(100vw - 65vw); /* Максимальная ширина (80% от левой секции минус 40px отступов) */
  overflow-y: auto; /* Добавить вертикальную прокрутку при необходимости */
}

label {
  margin-right: 10px;
}

.payment-button {
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 150px;
  height: 100px;
  background-size: contain; /* Растягивает и обрезает картинку, чтобы она покрыла всю кнопку */
  background-repeat: no-repeat; /* Запрет повторения фона */
  background-position: center; /* Центрирует картинку на кнопке */
}

.button-clicked {
  /* Стили для кнопки после нажатия */
  border-color: #f74fa9; /* Пример изменения цвета границы */
  box-shadow: 0 0 5px rgba(247, 79, 169, 0.5); /* Тень при активации и фокусе */
}

.payment-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #7d7d7d;
  color: white; /* Цвет текста */
  font-size: 26px;
  text-align: center; /* Выравнивание текста по центру */
  font-weight: bold;
  -webkit-text-stroke: 0.5px blue; /* Обводка текста */
}

.payment-options {
  display: flex;
  justify-content: flex-start; /* или другое значение в зависимости от вашего дизайна */
  /* другие стили */
}
</style>
