<template>
  <div>
    <h1>Payment Result</h1>
    <div v-if="status === 'success'">
      <p>Payment was successful!</p>
      <p>Transaction ID: {{ transactionId }}</p>
      <p>Amount: {{ amount }}</p>
      <p>Currency: {{ currency }}</p>
    </div>
    <div v-else>
      <p>Payment failed.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transactionId: '',
      status: '',
      amount: 0,
      currency: ''
    };
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    this.transactionId = params.get('transaction_id') || '';
    this.status = params.get('status') || '';
    this.amount = params.get('amount') || 0;
    this.currency = params.get('currency') || '';
  }
};
</script>
