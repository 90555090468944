<template>
  <div class="cart-page">
    <div class="cart-wrapper">
      <div class="cart-content">
        <div class="cart-items">
          <table class="cart-table">
            <thead>
              <tr>
                <th>{{ $t('cart.image') }}</th>
                <th>{{ $t('cart.title') }}</th>
                <th>{{ $t('cart.price') }}</th>
                <th>{{ $t('cart.quantity') }}</th>
                <th>{{ $t('cart.total') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in cartWithDetails" :key="product.id">
                <td><img :src="product.image" :alt="product.title" class="product-image-cart"></td>
                <td>{{ getProductTitle(product.id) }}</td>
                <td>{{ product.price }} €</td>
                <td>
                  <button @click="removeFromCart(product)" class="remove-button">✖</button>
                  <button @click="decrementQuantity(product)">-1</button>
                  <input v-model.number="product.quantity" type="number" min="1" max="100">
                  <button @click="incrementQuantity(product)">+1</button>
                </td>
                <td>{{ (product.price * product.quantity).toFixed(2) }} €</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4">{{ $t('cart.subtotal') }}</td>
                <td>{{ getSubtotal }} €</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="summary-container">
          <div class="delivery-options">
            <label>
              <input type="radio" v-model="selectedDelivery" value="pickup" @change="updateDeliveryType">
              {{ $t('cart.pickup') }}
            </label>
            <div class="delivery-description">
              {{ $t('cart.pickupDescription') }}
            </div>
            <label>
              <input type="radio" v-model="selectedDelivery" value="delivery" @change="updateDeliveryType">
              {{ $t('cart.delivery') }}
            </label>
            <div class="delivery-description">
              {{ $t('cart.deliveryDescription') }}
            </div>
            <label>
              <input type="radio" v-model="selectedDelivery" value="urgentDelivery" @change="updateDeliveryType">
              {{ $t('cart.urgentDelivery') }}
            </label>
            <div class="delivery-description">
              {{ $t('cart.urgentDeliveryDescription') }}
            </div>
          </div>
          <div class="order-summary">
            <h2>{{ $t('cart.orderTotal') }}</h2>
            <p>{{ finalPrice }} €</p> <!-- Итоговая сумма с учетом доставки -->
            <router-link to="/checkout" class="order-button no-underline custom-button" @click.prevent="createOrder">
              {{ $t('cart.orderButton') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { router } from '@/router';
import productsData from "@/components/products.json";
import ProductsTranslationsEE from "@/locales/est/ProductsTranslationsEE.json";
import ProductsTranslationsRU from "@/locales/rus/ProductsTranslationsRU.json";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      selectedDelivery: null,
      deliveryCosts: {
        'pickup': 0.00,
        'delivery': 7.99,
        'urgentDelivery': 14.99,
      },
    };
  },

  computed: {
    localizedCartItems() {
      return this.cart.map(item => {
        const productDetails = this.getProductDetails(item.id); // Функция для получения деталей товара
        return {
          ...item,
          title: productDetails.title[this.currentLanguage], // Предположим, что title - это объект с ключами языков
          description: productDetails.description[this.currentLanguage], // Аналогично для описания
        };
      });
    },
    cart() {
      return this.$store.state.cart;
    },
    getSubtotal() {
      // Это свойство теперь возвращает значение upCost из Vuex
      return this.$store.getters.upCost;
    },
    ...mapGetters(['totalCost', 'deliveryCost', 'finalPrice']),
    cartWithDetails() {
      return this.cart.map(item => {
        const product = productsData.find(p => p.id === item.id);
        // Проверка на существование продукта гарантирует, что мы не получим ошибку при обращении к несуществующим свойствам
        return {
          ...item,
          image: product?.images[0] || '/path/to/default/image.png', // Берём первое изображение или изображение по умолчанию
          price: product?.price || item.price, // Обновляем цену на основе данных products.json или используем существующую, если продукт не найден
        };
      });
    },
  },

  methods: {
    extractDeliveryCost(option) {
      return this.deliveryCosts[option] || 0;
    },
    updateDeliveryType() {
      const deliveryCost = this.extractDeliveryCost(this.selectedDelivery);
      this.$store.commit('setDeliveryType', this.selectedDelivery);
      this.$store.commit('setDeliveryCost', deliveryCost);
    },
    removeFromCart(product) {
      this.$store.dispatch('removeFromCart', product);
    },
    getProductTitle(productId) {
      const currentLanguageTranslations = this.$store.state.currentLanguage === 'est' ? ProductsTranslationsEE : ProductsTranslationsRU;
      return currentLanguageTranslations[productId]?.title || 'Название продукта отсутствует';
    },
    addToCart(product) {
      this.$store.commit('addToCart', product);
    },
    incrementQuantity(product) {
      this.$store.commit('incrementQuantity', product);
    },
    decrementQuantity(product) {
      this.$store.commit('decrementQuantity', product);
    },
    createOrder() {
      if (this.selectedDelivery === null) {
        console.error("Выберите тип доставки перед оформлением заказа.");
        return;
      }

      this.$store.commit('setDeliveryType', this.selectedDelivery);

      const orderData = {
        selectedDelivery: this.selectedDelivery,
        orderTotal: this.finalPrice,
        products: this.cart,
      };

      console.log("Order data:", orderData); // Отображаем данные заказа в консоли

      // Просто выполните перенаправление на страницу оплаты без указания URL-адреса
      this.$router.push({ name: 'payment' });
    },
  },
};
</script>

<style>
.remove-button {
  background-color: #ff0000; /* Цвет кнопки удаления */
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  padding: 4px 6px; /* Измените размеры по вашему выбору */
  margin-right: 6px; /* Расстояние между кнопкой удаления и кнопкой "-1" */
  border-radius: 5px;
}

.cart-wrapper {
  width: 80%;
  margin: 0 auto;
}

.cart-page {
  text-align: center;
  background-color: #ffd9e6;
  padding-top: 1%;
  padding-bottom: 17%;
}

.cart-content {
  display: flex;
  justify-content: space-between;
}

.cart-items {
  flex: 0 0 60%;
  margin-right: 1%;
}

.cart-table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
}

.delivery-address-input::placeholder {
  color: #ccc;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.cart-table th,
.cart-table td {
  background-color: #fff; /* Задайте белый цвет фона */
  border: 1px solid #ccc;
  padding: 4px;
  text-align: center;
  font-size: 14px;
}

.cart-table th {
  background-color: #f2f2f2;
}

.cart-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.cart-table tfoot td {
  text-align: right;
}

.product-image-cart {
  max-width: 100px;
  max-height: 100px;
  display: block;
  margin: 0 auto;
}

.summary-container {
  display: flex;
  flex-direction: column;
}

.delivery-options {
  background-color: white;
  border: 1px solid #f74fa9;
  border-radius: 10px;
  padding: 20px;
}

.delivery-description {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  text-align: left;
}

.delivery-options label {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
}

.delivery-options input[type="radio"] {
  margin-right: 10px;
}

.order-summary {
  margin-top: 20px;
  background-color: white;
  border: 1px solid #f74fa9;
  border-radius: 10px;
  text-align: left;
  padding: 20px;
}

.order-summary h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.order-summary p {
  font-size: 18px;
  font-weight: bold;
}

.order-button {
  background-color: #f74fa9;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  border-radius: 5px;
  display: block;
  text-align: center;
  box-sizing: border-box;
}

.no-underline {
  text-decoration: none !important;
}

.custom-button {
  width: 100%;
}

.order-button:hover {
  background-color: #d64994;
  cursor: pointer;
}

.cart-items button {
  background-color: #ccc;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  padding: 4px 10px;
}
</style>
