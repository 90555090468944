<template>
  <div id="app">
    <AppHeader /> <!-- Вставляем хедер -->
    <router-view /> <!-- Отображаем компоненты маршрутов -->
    <AppFooter /> <!-- Вставляем футер -->
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  created() {
    this.$store.dispatch('loadCart');
  },
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

<style>
#app {
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
margin: 0px !important;
}
</style>
