import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import ProductsPage from '@/components/ProductsPage.vue';
import AboutPage from '@/components/AboutPage.vue';
import CartPage from '@/components/CartPage.vue';
import PaymentPage from '@/components/PaymentPage.vue';
import TestProductPage from '@/components/TestProductPage.vue';
import PaymentResultPage from '@/components/PaymentResultPage.vue';  // Импортируем новый компонент

const routes = [
  {
    path: '/',
    component: HomePage,
    name: 'home',
  },
  {
    path: '/products',
    component: ProductsPage,
    name: 'products',
  },
  {
    path: '/about',
    component: AboutPage,
    name: 'about',
  },
  {
    path: '/cart',
    component: CartPage,
    name: 'cart',
  },
  {
    path: '/payment',
    name: 'payment',
    component: PaymentPage,
    props: (route) => ({ total: route.query.total }),
  },
  {
    path: '/payment-result',  // Добавляем новый маршрут
    name: 'payment-result',
    component: PaymentResultPage,
  },
  {
    path: '/product-1',
    component: TestProductPage,
    name: 'product',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
