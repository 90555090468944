<template>
  <div class="about-page">

    <!-- Блок "О Bel Fiore" -->
    <div class="about-bel-fiore">
      <h2>О Bel Fiore</h2>
      <p>Цветочный магазин - это место, где мечты расцветают в ярких красках. У нас вы найдете огромный выбор цветов, способных подарить тепло и радость в самые важные моменты вашей жизни. Наши букеты - это искусно созданные композиции, каждый из которых уникален, словно маленькое произведение искусства."

"Наша команда флористов с большой любовью и вниманием к деталям работает над каждым заказом, чтобы он отражал вашу неповторимую индивидуальность и чувства. В нашем магазине вы найдете не только цветы, но и внимательное отношение к вашим пожеланиям и гарантированный источник вдохновения для создания незабываемых моментов в вашей жизни.</p>
    </div>

    <!-- Блок "О Владелице" -->
    <div class="about-owner">
      <h2>О Надежде Сергееве</h2>
      <p>Владелица нашего волшебного цветочного магазина, Надежда Сергеева, - это настоящая хранительница красоты и вдохновения. Надежда - человек, который влюблен в цветы и знает, как они могут изменить наше восприятие мира. Её страсть к флористике и неистощимая энергия делают этот магазин по-настоящему особенным местом."

"С удивительным вкусом и креативностью, Надежда всегда готова воплотить в жизнь самые необычные идеи клиентов, создавая букеты и композиции, которые удивляют и восхищают. Её преданность качеству и долгосрочным отношениям с клиентами делают её не только владелицей, но и душой нашего цветочного магазина. Надежда придает нашему магазину индивидуальность и теплоту, которые делают его настоящим убежищем для всех, кто ищет красоту в цветах.</p>
    </div>

<!-- Блок "Часы работы" (как в исходном коде) -->
<div class="business-hours">
<div class="business-hours-container">
  <h2>Режим работы</h2>
  <ul>
    <li><span>Понедельник:</span> 09:00 - 17:00</li>
    <li><span>Вторник:</span> 09:00 - 17:00</li>
    <li><span>Среда:</span> 09:00 - 17:00</li>
    <li><span>Четверг:</span> 09:00 - 17:00</li>
    <li><span>Пятница:</span> 09:00 - 17:00</li>
    <li><span>Суббота:</span> 10:00 - 15:00</li>
    <li><span>Воскресенье:</span> Закрыто</li>
  </ul>
</div>
</div>

    <!-- Блок "Фото места" (как в исходном коде) -->
    <div class="location-photos">
      <h2>Мы тут</h2>
      <div class="location-images">
        <img src="/images/zveti_dlya_osennego_buketa_1.jpg" alt="Местоположение 1">
        <img src="/images/zveti_dlya_osennego_buketa_1.jpg" alt="Местоположение 1">
      </div>
    </div>

    <!-- Блок "Гугл карта" (как в исходном коде) -->
    <div class="google-map">
      <h2>Местоположение на Google Картах</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2029.931229549369!2d24.86075697727646!3d59.41754210395644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692ec83faf9b1e7%3A0xa6e9766382b63f1!2sVisase%208%2C%2011415%20Tallinn!5e0!3m2!1sru!2see!4v1703540263545!5m2!1sru!2see"
        width="100%"
        height="450"
        style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>

<style scoped>
.about-page {
  text-align: center;
  padding: 1% 100px 1% 100px;
  background-color: #ffd9e6;
}

.page-title {
  font-size: 32px;
  margin-bottom: 10px;
}

.page-description {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
}

.business-hours {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
}

.business-hours h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.business-hours ul {
  list-style-type: none;
  padding: 0;
}

.business-hours li {
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.business-hours li span {
  font-weight: bold;
  text-align: right;
}

.business-hours-container{
  width: 22vw;
}

.location-photos {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.location-photos h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.location-images {
  text-align: center; /* Центрируем изображения */
}

.location-images img {
  display: inline-block; /* Размещаем изображения в ряд по горизонтали */
  max-width: 100%;
  height: auto; /* Сохраняем пропорции изображений */
  width: 30vw; /* Задаем фиксированную ширину изображений */
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}




.google-map {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.google-map h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Стили для блока "О Bel Fiore" */
.about-bel-fiore {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.about-bel-fiore h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Стили для блока "О Владелице" */
.about-owner {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.about-owner h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
</style>
