import { createApp } from 'vue';
import App from './App.vue';
import store from './vuexStore';
import router from './router';
import { createI18n } from 'vue-i18n';
import messages from './locales';

const i18n = createI18n({
  locale: store.state.currentLanguage, // Инициализация с языком из Vuex
  fallbackLocale: 'rus', // Запасной язык
  messages,
});


const app = createApp(App);

app.use(store).use(router).use(i18n).mount('#app');

// Наблюдение за изменением языка в состоянии Vuex и обновление языка в vue-i18n
store.watch(
  (state) => state.currentLanguage,
  (newLang) => {
    i18n.global.locale = newLang;
  }
);

// Вызываем действие Vuex после монтирования приложения
store.dispatch('checkLanguageFromCookie');
