// locales/index.js
import AppHeaderEE from './est/AppHeaderEE.json';
import AppHeaderRU from './rus/AppHeaderRU.json';
import HomePageEE from './est/HomePageEE.json';
import HomePageRU from './rus/HomePageRU.json';
import ProductsPageEE from './est/ProductsPageEE.json';
import ProductsPageRU from './rus/ProductsPageRU.json';
import CartPageEE from './est/CartPageEE.json';
import CartPageRU from './rus/CartPageRU.json';
import PaymentPageEE from './est/PaymentPageEE.json';
import PaymentPageRU from './rus/PaymentPageRU.json';
import ProductsTranslationsEE from './est/ProductsTranslationsEE.json';
import ProductsTranslationsRU from './rus/ProductsTranslationsRU.json';
import CategoriesTranslationsEE from './est/CategoriesTranslationsEE.json';
import CategoriesTranslationsRU from './rus/CategoriesTranslationsRU.json';
import TagTranslationsEE from './est/TagTranslationsEE.json';
import TagTranslationsRU from './rus/TagTranslationsRU.json';

export default {
  est: {
    ...HomePageEE,
    ...AppHeaderEE,
    ...ProductsPageEE,
    ...CartPageEE,
    ...PaymentPageEE,
    ...ProductsTranslationsEE,
    ...CategoriesTranslationsEE,
    ...TagTranslationsEE
  },
  rus: {
    ...HomePageRU,
    ...AppHeaderRU,
    ...ProductsPageRU,
    ...CartPageRU,
    ...PaymentPageRU,
    ...ProductsTranslationsRU,
    ...CategoriesTranslationsRU,
    ...TagTranslationsRU
  }
};


