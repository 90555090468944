<template>
  <div>
 <div class="rectangle-container">
      <div
        class="rectangle"
        v-for="(item, index) in localizedRectangleItems"
        :key="index"
        @mouseenter="showDetails(index)"
        @mouseleave="hideDetails"
      >
        <img class="rectangle-image" :src="item.imageSrc" alt="rectangle image" />
        <div class="rectangle-details" :class="{ 'visible': hoveredIndex === index }">
          <div class="rectangle-title">{{ item.name }}</div>
          <div class="rectangle-price">{{ item.price }}€</div>
          <button class="rectangle-add-to-cart-btn" @click="addToCart(item)">Добавить в корзину</button>
        </div>
      </div>
    </div>

    <div class="product-list-container">
      <h1 class="popular-products-title">{{ $t('popularProductsTitle') }}</h1>
      <div class="product-list-mesh">
        <div v-for="product in localizedProducts" :key="product.id" class="product-small">
          <div class="product-square" :style="{ backgroundImage: 'url(' + product.images[0] + ')' }"></div>
          <h3>{{ product.title }}</h3>
          <p>{{ $t('price') }}: {{ product.price }}€</p>
          <button class="add-to-cart-btn">{{ $t('addToCart') }}</button>
        </div>
      </div>
    </div>

    <div class="fixed-background">
      <div class="overlay"></div>
      <div class="text-overlay">
        <h1>{{ $t('aboutTitle') }}</h1>
        <h2>{{ $t('aboutSubtitle') }}</h2>
        <p>{{ $t('aboutText') }}</p>
      </div>
    </div>
  </div>
</template>



<script>
import productsData from './products.json'; // Импортируем данные из products.json
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getProductTranslation: 'getProductTranslation' // Используем геттер из Vuex
    }),
    
    localizedRectangleItems() {
      return this.rectangleItems.map(item => {
        const translation = this.getProductTranslation(item.id) || {};
        return {
          ...item,
          name: translation.title || item.name // Если перевод доступен, используем его
        };
      });
    },

    localizedProducts() {
      return this.products.map(product => {
        const translation = this.getProductTranslation(product.id) || {};
        return {
          ...product,
          ...translation
        };
      });
    }
  },

  data() {
    return {
      rectangleItems: productsData.filter(product => [6, 7, 8].includes(product.id)).map(product => ({
        id: product.id, // Сохраняем ID для использования в локализации
        imageSrc: product.images[0], // Первое изображение товара
        name: product.title, // Оригинальное название товара
        price: product.price // Добавляем цену товара
      })),
      products: productsData.filter(product => [1, 2, 3, 4, 5].includes(product.id)),
      hoveredIndex: null // Добавляем свойство для отслеживания наведения
    };
  },

  methods: {
    showDetails(index) {
      this.hoveredIndex = index;
    },
    hideDetails() {
      this.hoveredIndex = null;
    }
  }
};
</script>





<style scoped>
.rectangle-details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0; /* Изначально скрыты */
  transition: opacity 0.3s ease; /* Плавное появление */
  width: 60%;
}

.rectangle-details.visible {
  opacity: 1; /* Показываем */
}
.rectangle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрируем содержимое по горизонтали */
  width: 80%; /* Максимальная ширина контейнера, например, 80% от ширины родителя */
}

.rectangle-title, .rectangle-price, .rectangle-add-to-cart-btn {
  width: 100%; /* Заставляем элементы занимать всю ширину контейнера */
  background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон для улучшения читаемости */
  padding: 5px 10px;
  margin: 5px 0; /* Добавляем небольшой отступ между элементами */
  border-radius: 5px; /* Скругляем углы */
  box-sizing: border-box; /* Убедимся, что padding не увеличит ширину элементов */
  color: white;
}

.rectangle-add-to-cart-btn {
  background-color: #f74fa9; /* Зеленый фон */
  color: white; /* Белый текст */
  border: none; /* Убрать стандартные рамки */
  cursor: pointer; /* Курсор в виде руки */
  transition: background-color 0.3s; /* Плавное изменение фона при наведении */
}

.rectangle-add-to-cart-btn:hover {
  background-color: #ce4990; /* Темнее оттенок при наведении */
}



.add-to-cart-btn {
  width: 100%; /* Заставляет кнопку занимать всю ширину родительского элемента */
  padding: 10px 0; /* Обновленный отступ для сохранения высоты кнопки */
  background-color: #f74fa9; /* Пример цвета фона */
  color: white; /* Цвет текста */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}


.add-to-cart-btn:hover {
  background-color: #ce4990; /* Темнее зеленый при наведении */
}


.product-square {
  width: 100%;
  padding-top: 100%; /* Пропорция 1:1 */
  background-color: #f0f0f0; /* Пример цвета фона, можно изменить */
  background-size: cover; /* Указываем, что фон должен покрыть весь контейнер */
  background-position: center; /* Центрируем изображение */
  margin-bottom: 10px; /* Отступ от квадрата до содержимого карточки */
  border-radius: 5px; /* Добавляет скругленные углы к изображению */
}

.popular-products-title{
  margin: 0;
  padding: 40px 0 0 0;
}

.product-list-container {
  text-align: center; /* Выравниваем текст по центру */
  background-color: #ffd9e6;
}

.product-list-mesh {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  margin-top: 20px; /* Добавляем верхний отступ между заголовком и карточками */
}

.product-list-mesh h1 {
  font-size: 24px; /* Увеличиваем размер шрифта заголовка */
  margin-bottom: 10px; /* Увеличиваем отступ снизу заголовка */
  color: #333; /* Изменяем цвет текста заголовка */
}

.product-small {
  flex-basis: calc(33% - 20px); /* Пример для трех карточек в ряд на больших экранах */
  margin: 10px;
  padding: 15px; /* Увеличенный внутренний отступ */
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 13px;
  max-width: 300px; /* Увеличенная максимальная ширина */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  min-height: 400px; /* Увеличенная минимальная высота */
}



.product-small h3 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.product-small p {
  font-size: 12px;
  margin-bottom: 5px;
}

.product-image-for-list {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
  border-radius: 10px;
}


.fixed-background {
  position: relative;
  background-image: url('@/assets/272728-2118x1412-summer-flowers.jpg');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  height: 100vh;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.rectangle-container {
  display: flex;
  flex-wrap: wrap;
}

.rectangle {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
  position: relative;
}

.rectangle-image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rectangle:hover .rectangle-image {
  filter: brightness(70%) blur(1px);
}



.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  color: white;
  border-radius: 0px;
  text-align: center;
  border: 2px solid white;
}

.text-overlay h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.text-overlay h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.text-overlay p {
  font-size: 18px;
}
</style>

